import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header";

export default function Speaker({ data }) {
  const post = data.markdownRemark

  const contact = post.frontmatter.contact ? post.frontmatter.contact.split(',') : null;

  return (
    <div>
      <Header />
      <div className="content profile">
        <h1>{post.frontmatter.name}</h1>
        <div className="inner">
            <div className="about">
              <div className="info">
                <p>
                {post.frontmatter.role ? (
                  post.frontmatter.role + ", "
                ) : ""}
                {post.frontmatter.institution ? (
                  post.frontmatter.institution
                ) : ""}
                </p>
                {post.frontmatter.institutions && post.frontmatter.institutions[0] ? (
                  <p>{post.frontmatter.institutions[0]}</p>
                ) : ""}
                {contact && contact[0] ? (
                  <p><a href={"mailto:" + contact[0].replace(/\s/g, '')}>{contact[0]}</a></p>
                ) : ("")}
                {post.frontmatter.institutions && post.frontmatter.institutions[1] ? (
                  <p>{post.frontmatter.institutions[1]}</p>
                ) : ""}
                {contact && contact[1] ? (
                  <p><a href={"mailto:" + contact[1].replace(/\s/g, '')}>{contact[1]}</a></p>
                ) : ("")}
                {post.frontmatter.institutions && post.frontmatter.institutions[2] ? (
                  <p>{post.frontmatter.institutions[2]}</p>
                ) : ""}
                {contact && contact[2] ? (
                  <p><a href={"mailto:" + contact[2].replace(/\s/g, '')}>{contact[2]}</a></p>
                ) : ("")}
              </div>
              <div className="image">
                <div className="image-wrapper">
                  <img src={post.frontmatter.image.publicURL} alt={post.frontmatter.name} />
                </div>
                <p className="caption">{post.frontmatter.caption}</p>
              </div>
            </div>
            <div className="abstract">
              <h2>{post.frontmatter.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
          </div>
      </div>
    </div>
  )
}


export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        name
        role
        institution
        institutions
        contact
        image {
          childImageSharp {
            fluid {
              originalImg
              src
            }
          }
          publicURL
        }
        title
        caption
      }
    }
  }
`
